.jtc-component-disruptor {
  display: none;
  padding: 16px 0;
  z-index: 10;
  position: relative;
}
.jtc-component-disruptor.is-sticky {
  z-index: 300;
  position: sticky;
  top: 0;
}
.jtc-component-disruptor.show {
  display: block;
}
.jtc-component-disruptor.symetric .disruptor-inside .title-image {
  display: flex;
  flex: 1;
  justify-content: flex-start;
}
.jtc-component-disruptor.symetric .disruptor-inside .content-countdown {
  display: flex;
  flex: 1;
  justify-content: center;
  margin-right: initial;
}
.jtc-component-disruptor.symetric .disruptor-inside .actions {
  display: flex;
  flex: 1;
  justify-content: flex-end;
}
.jtc-component-disruptor .disruptor-inside {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  font-weight: 400;
}
@media (min-width: 768px) {
  .jtc-component-disruptor .disruptor-inside {
    gap: 40px;
    flex-direction: row;
  }
}
@media (max-width: 768px) {
  .jtc-component-disruptor .disruptor-inside .title-image {
    width: 100%;
  }
}
.jtc-component-disruptor .disruptor-inside .title-image .image {
  width: 120px;
  aspect-ratio: 2/1;
}
@media (max-width: 768px) {
  .jtc-component-disruptor .disruptor-inside .title-image .image {
    margin: 0 auto;
  }
}
.jtc-component-disruptor .disruptor-inside .title-image .title {
  margin: 0 0 16px 0;
  text-align: left;
}
@media (min-width: 768px) {
  .jtc-component-disruptor .disruptor-inside .title-image .title {
    margin-bottom: 0;
  }
}
.jtc-component-disruptor .disruptor-inside .content-countdown {
  margin-right: auto;
}
.jtc-component-disruptor .disruptor-inside .content-countdown:not(.countdown) {
  padding-right: 24px;
}
.jtc-component-disruptor .disruptor-inside .content-countdown p {
  padding: 0;
  margin: 0;
}
.jtc-component-disruptor .disruptor-inside .actions {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media (max-width: 768px) {
  .jtc-component-disruptor .disruptor-inside .actions .button {
    margin-top: 16px;
  }
}
.jtc-component-disruptor .disruptor-inside .actions .close-disruptor {
  color: white;
  cursor: pointer;
  font-size: 24px;
}
@media (max-width: 768px) {
  .jtc-component-disruptor .disruptor-inside .actions .close-disruptor {
    position: absolute;
    top: 8px;
    right: 16px;
  }
}
.jtc-component-disruptor .disruptor-inside a {
  color: inherit;
  font-weight: bolder;
  text-decoration: none;
}

