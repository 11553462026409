.jtc-input-label {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: flex-start;
  letter-spacing: 0.96px;
  width: 100%;

  > span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
    background-color: inherit;
    width: 100%;
    max-width: 95%;
  }

  .tooltip-wrapper-element {
    margin-left: 5px;
  }
}
