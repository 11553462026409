@import "../../../styles/lib/variables";

$navigation-mobile__background: $color-white;

.with-transparent-navbar {
  .jtc-component-disruptor.show ~ .jtc-navigation-component {
    position: sticky;
    top: var(--disruptor-height, 0px);
    margin-bottom: -88px;
    @media (min-width: $screen-md) {
      margin-bottom: -98px;
    }
  }
}

.jtc-navigation-component,
.with-transparent-navbar .jtc-component-disruptor.show ~ .jtc-navigation-component {
  &.isNavigationOpen {
    position: fixed;
    top: 0;
    z-index: 500;
  }
}

.jtc-navigation-component {
  position: sticky;
  top: var(--disruptor-height, 0px);
  z-index: 110;
  width: 100%;
  padding: 14px 0;
  background: $primary__color__medium;
  transition: transform 200ms ease-in-out;

  @media (min-width: $screen-sm) {
    padding: 20px 0;
  }

  &-headroom {
    &--pinned,
    &--unpinned {
      &.isProfileNavigationOpen,
      &.isNavigationOpen {
        transform: none;
      }
    }

    &--pinned {
      transform: translateY(0%);
    }

    &--unpinned {
      transform: translateY(-100%);
    }
  }

  .with-transparent-navbar & {
    background: transparent;
    position: fixed;
    top: 0;
  }

  .with-color-scheme.green:not(.with-transparent-navbar) & {
    background-color: $primary__color__medium;
  }

  .with-color-scheme.grey & {
    background-color: $shades-medium__color;
  }

  &.customerArea {
    padding-bottom: 0;
  }

  &.customerArea,
  &.isSmallHeader {
    background: $primary__color__medium;

    .navigation-container-wrapper {
      align-items: center;
    }
  }

  .jtc-global-search {
    cursor: pointer;

    @media (min-width: $screen-md) {
      margin-right: 16px;
    }

    svg {
      display: block;
    }
  }

  .logo-container {
    min-width: 120px;
    margin-right: auto;

    img {
      max-width: 100%;
      max-height: 52px;
      height: 100%;
      display: block;
      transition: all 0.2s ease-in-out;
    }
  }

  &.isProfileNavigationOpen,
  &.isNavigationOpen {
    transition: none;
  }

  &.isProfileNavigationOpen {
    background-color: $primary__color__medium;

    .profile-item-container {
      > .item-children-wrapper {
        display: block;
        z-index: 1;
      }
    }
  }

  &.isNavigationOpen {
    background-color: $primary__color__medium;

    .navigation-container {
      display: flex;
      z-index: 1;
    }
  }

  .navigation-container {
    display: none;
    @media (max-width: 991px) {
      position: fixed;
      left: 0;
      right: 0;
      bottom: 0;
      top: 80px;
      padding: 32px;
      background: $navigation-mobile__background;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      justify-content: space-between;
    }
    @media (min-width: 992px) {
      display: block;
    }
  }

  .navigation-container-wrapper {
    display: flex;
    align-items: center;

    @media (min-width: 992px) {
      max-width: 100%;
    }
  }

  .navigation-items {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: auto 1fr;
    column-gap: 12px;

    @media (min-width: 992px) {
      display: flex;
      align-items: center;
      flex-direction: row;
      gap: 16px;
    }
  }

  .profile-item-container {
    position: relative;
    margin-left: 16px;

    @media (max-width: 991px) {
      .jtc-navigation-menu {
        .jtc-navigation-item-component {
          margin-bottom: 40px;
        }
      }
    }

    @media (min-width: 992px) {
      &:hover {
        > .item-children-wrapper {
          display: block;
        }
      }
    }

    .item-children-wrapper {
      display: none;
      padding-top: 23px;
      right: 0;

      @media (max-width: 991px) {
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: 80px;
        margin-top: 0;
        background: #fff;
        overflow-y: auto;
        padding: 32px;
      }

      a {
        white-space: nowrap;
      }
    }
  }
}

.user-navigation-container-wrapper {
  background: #eae8e7;
  margin-top: 24px;

  &.container {
    max-width: 100%;
    padding-inline: 0;
  }

  .customer-navigation {
    &-renderer {
      padding-left: 16px;
      scroll-padding-left: 16px;
      @media (min-width: $screen-md) {
        padding-left: 32px;
        scroll-padding-left: 32px;
      }
    }
  }

  .jtc-navigation-menu {
    max-width: 100%;

    .item-children {
      display: flex;
      border-radius: 0;
      padding: 0 $container-mobile__padding 0 0;
      box-shadow: none;
      gap: 40px;
      background: transparent;
      margin: 0;

      .jtc-navigation-item-component {
        padding-block: 16px;
        margin: 0;
        border-top: 4px solid transparent;
        border-bottom: 4px solid transparent;
        white-space: nowrap;

        &.active {
          border-bottom: 4px solid $theme__color__primary;
        }
      }
    }
  }
}
